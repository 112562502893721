<template>
  <div>
    <b-modal
        id="modal-center"
        v-model="showAnalysices"
        no-close-on-backdrop

        centered
        hide-footer
        size="lg"
    >
      <b-overlay
          :show="invoiceModalLoading"
          rounded="sm"
      >

      <b-row class=" ">

        <b-col cols=""  xl="4">
          <div>
            <h4 class="mb-0 font-weight-bolder">Incoming Analtics</h4>
          </div>    
        </b-col>

        <b-col cols="" md="" xl="4" class="text-right mb-1">
              <div>
                  <b-button-group class="bg-white">
                      <b-button v-ripple.400="'rgba(113, 102, 240, 0.15)'" variant="outline-primary">
                            Week
                      </b-button>
                      <b-button v-ripple.400="'rgba(113, 102, 240, 0.15)'" variant="outline-secondary">
                            Month
                      </b-button>
                      <b-button v-ripple.400="'rgba(113, 102, 240, 0.15)'" variant="outline-secondary">
                            Year
                    </b-button>
                  </b-button-group>
              </div>
        </b-col>

        <b-col cols=""  xl="3" class="">
        
          <b-form-group>
            <v-select
                class="bg-white"
                placeholder="This Week: Jun 14 - Jun 20"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              label="title3"
            />
          </b-form-group>
       
        </b-col>
        <b-col cols=""  xl="0">
         
          <b-button
              size="23"
              variant="outline-white"
              class="btn-icon bg-white text-primary ml-1" >

            <feather-icon
              size="18"
              icon="DownloadIcon"
            />
          </b-button>
                   
        </b-col>
      </b-row>
    
    <b-col cols="10"  class="pb-2 pt-2">
        <div class="row">

          <!-- 01 Total Statement -->

          <div class="d-flex align-items-center mr-3 mb-1">

            <b-avatar variant="light-primary" rounded>
              <span style="font-size: 150%">£</span>
            </b-avatar>

            <div class="ml-1">
              <h4 class="mb-0 font-weight-bolder "> £45,320.00 </h4>
              <small>Recieved</small>
            </div>
          </div>

          <!-- 02 Total Paid -->

          <div class="d-flex align-items-center mr-3 mb-1">

            <b-avatar variant="light-primary" rounded>
              <feather-icon icon="TrendingUpIcon" size="18" />
              
            </b-avatar>

            <div class="ml-1">
              <h4 class="mb-0 font-weight-bolder"> £98,750.00 </h4>
              <small>Forcasted</small>
            </div>
          </div>
          
        </div>
      </b-col>


  <b-card>
    <b-card-header class="mt-n2">
      <div class="d-flex align-items-center ">
        <div>
          <h4 class="mb-0 font-weight-bolder ">Recieved & Forcasted Income</h4>
        </div>
      </div>
      <div class="ml-10 mt-1">
          <b-row>
            <div class="ml-5">
              <ul style="list-style-type:none;">
                <li style=""><span class="badge badge-primary" style="font-size: 0px">.</span><li>
                <li style=""><small>Recieved</small></li>
              </ul>
            </div>
            <div class="">
              <ul style="list-style-type:none;">
                <li style=""><span class="badge badge-success" style="font-size: 0px">.</span></li>
                <li style=""><small>Forcasted</small></li>
              </ul>
            </div>
          </b-row>
      </div>
      <b-card-text class="font-medium-5 mb-0">
        <feather-icon
          icon="HelpCircleIcon"
          size="21"
          class="text-muted cursor-pointer"
        />
      </b-card-text>
    </b-card-header>    
    <b-card-body>
      <!-- <canvas id="myChart" width="400" height="400"  :option-data="option">
        
      </canvas> -->
      <app-echart-bar
      :option-data="option"
    />
    </b-card-body>
 </b-card >



      </b-overlay>
      </b-modal>

  </div>
</template>

<script>
import PaidInvoice from "@/views/invoice/paid-invoice";
import OutstandingInvoice from "@/views/invoice/outstanding-invoice";
import {
  BAlert,
  BAvatar, BAvatarGroup, BBadge,
  BButton, BButtonGroup,
  BCard, BCardBody,
  BCardHeader,
  BCardText, BCardTitle,
  BCol, BDropdown, BDropdownItem, BFormCheckbox, BFormGroup, BFormInput,
  BImg, BInputGroup, BInputGroupPrepend, BLink, BListGroupItem,
  BMedia, BModal, BOverlay, BPagination,
  BRow,
  BTab, BTable,
  BTabs, VBModal, VBToggle,
  VBTooltip
} from "bootstrap-vue";
import flatPickr from "vue-flatpickr-component";
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import { Bar } from 'vue-chartjs'
import AppEchartBar from '@core/components/charts/echart/AppEchartBar.vue'
// var ctx = document.getElementById('myChart').getContext('2d');

export default {
  name: "analytics",
  components: {
    AppEchartBar,
    PaidInvoice,
    OutstandingInvoice,
    BTabs,
    BCardText,
    BTab,
    flatPickr,
    VBTooltip,
    BMedia,
    BImg,
    BCard,
    BCardHeader,
    BRow,
    BCol,
    BButton,
    BAvatar,
    BLink,
    BCardBody,
    BBadge,
    VuePerfectScrollbar,
    BListGroupItem,
    BCardTitle,
    BFormGroup,
    VBToggle,
    BModal, VBModal, BAlert,
    BButtonGroup,
    BOverlay, BFormCheckbox,
    BInputGroup,
    BInputGroupPrepend,
    BAvatarGroup,
    BFormInput,
    BTable,
    BDropdown,
    BDropdownItem,
    BPagination,
    Bar
  },
  extends: Bar,
  // props: ['chartdata', 'options'],
  // mounted () {
  //   this.renderChart(this.chartdata, this.options)
  // },
  data(){
    return{
      option: {
        legend: {
          show: false,
          left: '0',
          bottom: '0',
        },
        xAxis: [
          {
            type: 'category',
            data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun' ],
            splitLine: {show:false},
          },
        ],
        yAxis: [
          {
            type: 'value',
            min: 0,
            max: 4000,
            stepSize: 500,
            splitLine: { show: true },
          },
        ],
        grid: {
          left: '40px',
          right: '0px',
          bottom: '20px',
        },
        series: [
          {
            name: '',
            type: 'bar',
            stack: '',
            data: [500, 1000, 1500, 2000, 2500, 3000, 3500],
            barMaxWidth: '20%',
            barMinWidth: '10px',
             itemStyle: {
            barBorderRadius: [10, 10, 0, 0],
            color: [ "#28c76f"],
            },
          },
          {
            name: '',
            type: 'bar',
            stack: '',
            data: [1000, 500, 2500, 2000, 2500, 1000, 2500],
            barMaxWidth: '20%',
            barMinWidth: '10px',
             itemStyle: {
            barBorderRadius: [10, 10, 0, 0],
            color: [ "#1B9AAA"],
            },
          },
        ],
      },
      // labels: ['Mon', 'Tue', 'Wen', 'Thu', 'Fri', 'Sat', 'Sun'],
        // datasets: [{
        //     label: 'Income',
        //     data: [12, 19, 3, 5, 2, 3, 4],
        //     backgroundColor: [
        //         'rgba(255, 99, 132, 0.2)',
        //         'rgba(54, 162, 235, 0.2)',
        //         'rgba(255, 206, 86, 0.2)',
        //         'rgba(75, 192, 192, 0.2)',
        //         'rgba(153, 102, 255, 0.2)',
        //         'rgba(255, 159, 64, 0.2)',
        //         'rgba(255, 159, 64, 0.2)'
        //     ],
        //     borderColor: [
        //         'rgba(255, 99, 132, 1)',
        //         'rgba(54, 162, 235, 1)',
        //         'rgba(255, 206, 86, 1)',
        //         'rgba(75, 192, 192, 1)',
        //         'rgba(153, 102, 255, 1)',
        //         'rgba(255, 159, 64, 1)',
        //         'rgba(255, 159, 64, 1)'
        //     ],
        //     borderWidth: 1
        // }],
      showAnalysices:false,
      invoiceModalLoading:false, 
    }
  },
  // options: {
  //   scales: {
  //     y: {
  //         beginAtZero: true
  //     }
  //   }
  // },
  methods:{
   openModal(){
     this.showAnalysices = true
   }
  }

  
}
</script>

<style scoped>

</style>
