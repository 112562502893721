<template>
  <div>
    <b-row class="mt-n1 ml-0 mr-0">

      <!-- Left side Icons -->

      <b-col cols="10"  class="pb-2 pt-2">
        <div class="row">

          <!-- 01 Total Statement -->

          <div class="d-flex align-items-center mr-3 mb-1">

            <b-avatar variant="light-primary" rounded>
              <feather-icon icon="DollarSignIcon" size="18"/>
            </b-avatar>

            <div class="ml-1">
              <h4 class="mb-0 font-weight-bolder "> {{getPrice(invoiceSummary.paid)}} </h4>
              <small>Total Received</small>
            </div>
          </div>

          <!-- 02 Total Paid -->

          <div class="d-flex align-items-center mr-3 mb-1">

            <b-avatar variant="light-warning" rounded>
              <feather-icon icon="DollarSignIcon" size="18" />
            </b-avatar>

            <div class="ml-1">
              <h4 class="mb-0 font-weight-bolder"> {{getPrice(invoiceSummary.outstanding)}} </h4>
              <small>Outstanding</small>
            </div>
          </div>



          <!-- 05 Total unpaid -->

<!--          <div class="d-flex align-items-center mr-3 mb-1">
            <b-avatar variant="light-danger" rounded>
              <feather-icon icon="CreditCardIcon" size="18" />
            </b-avatar>

            <div class="ml-1">
              <h4 class="mb-0 font-weight-bolder"> £11,300.00 </h4>
              <small>Total Unpaid</small>
            </div>
          </div>-->
        </div>
      </b-col>

      <!-- Right side Downloading button -->

<!--      <b-col cols="2"  class="pt-0 pb-2 pt-xl-2 pb-xl-0 ml-n1 ">
        <b-row >
          <div class="d-flex justify-content-end  mt-2 mt-md-0 pl-0 pl-md-0 ml-auto">

            <b-button
                size="23"
                variant="outline-white"
                class="btn-icon bg-white text-primary float-right"
            @click="openAnaltics">
              <feather-icon
                  size="18"
                  icon="BarChartIcon"
              />
              View Analytics


            </b-button>
            <b-button
                size="23"
                variant="outline-white"
                class="btn-icon bg-white text-primary ml-1" >

              <feather-icon
                  size="18"
                  icon="DownloadIcon"
              />
            </b-button>
          </div>
        </b-row>
      </b-col>-->
    </b-row>
    <b-card>
      <b-row class="mb-2">
        <b-col  cols="3">
          <b-form-group>
            <b-input-group class="input-group-merge">
              <b-input-group-prepend is-text>
                <feather-icon icon="SearchIcon" />
              </b-input-group-prepend>
              <b-form-input
                  id="icons-search"
                  placeholder="Search"
              />
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col  cols="3">
          <b-form-group>
            <v-select
                v-model="filter"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="[
                            {title:'The London General Practice', value:'hdhdh'},
                            {title:'Tottenham Hale Medical Practice', value:'hdhdh'},
                            {title:'Merton Medical Practice', value:'hdhdh'},
                            {title:'Clerkenwell Primary Care Network', value:'hdhdh'},
                            ]"
                class="mb-2"
                label="title"
                placeholder="All Organisations"
            />
          </b-form-group>
        </b-col>
        <b-col cols="6">
          <b-button class="float-right" variant="primary" @click=" openInvitationCrateModel()"> Create Invoice</b-button>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12">
          <b-overlay
              :show="tableLoading"
              rounded="sm"
          >

            <b-table
                :current-page="currentPage"
                :fields="fields"
                :items="getInvoiceList"
                :per-page="pagination.perPage"
                class="mobile_table_css"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :sort-direction="sortDirection"
                hover
                responsive
                ref="table"
            >
              <template #cell(duration)="data">
                <!--              <div v-if="data.item.is_day">-->
                <!--                <span v-if="data.item.duration > 1">{{data.item.duration}} days</span>-->
                <!--                <span v-else >{{data.item.duration}} day</span>-->
                <!--              </div>-->
                <!--              <div v-else>-->
                <!--                <span v-if="data.item.duration > 1" >{{data.item.duration}} hours</span>-->
                <!--                <span v-else >{{data.item.duration}} hour</span>-->

                <!--              </div>-->


              </template>

              <template #cell(status)="data">
                <b-badge v-if="data.item.status === 'Paid'"  class="width-100" style="padding:8px" variant="light-primary" >
                  <span class=""> {{data.item.status}} </span>
                </b-badge>
                <b-badge v-else  style="padding:8px" class="width-100" variant="light-warning" >
                  <span class=""> {{data.item.status}} </span>
                </b-badge>
                <!--              <b-badge v-if="data.item.status ==='Approved'" class="" style="padding:8px" variant="success" >-->
                <!--                <span class=""> {{data.item.status}} </span>-->
                <!--              </b-badge>-->
                <!--              <b-badge v-if="data.item.status ==='Rejected'" class="" style="padding:8px" variant="danger" >-->
                <!--                <span class=""> {{data.item.status}} </span>-->
                <!--              </b-badge>-->
                <!--              <b-badge v-if="data.item.status ==='Cancelled'" class="" style="padding:8px" variant="info" >-->
                <!--                <span class=""> {{data.item.status}} </span>-->
                <!--              </b-badge>-->


              </template>
              <template #cell(action)="data">
                <b-button
                    size=""
                    variant="outline-white"
                    class="btn-icon bg-white text-primary  "
                    v-b-tooltip.hover.top="'View'"
                    @click="$router.push(`/invoice/view/${data.item.id}`)"
                >
                  <feather-icon icon="EyeIcon" size="18" />
                </b-button>



                <!--              <b-button-->
                <!--                  size="23"-->
                <!--                  variant="outline-white"-->
                <!--                  class="btn-icon bg-white text-danger m-1"-->
                <!--                  v-b-tooltip.hover.top="'Reject'"-->
                <!--                  @click="rejectLeave(data.item.id)"-->
                <!--              >-->
                <!--                <feather-icon icon="XSquareIcon" size="18" variant="outline-danger"/>-->
                <!--              </b-button>-->


              </template>


            </b-table>
          </b-overlay>
        </b-col>
        <!-- Pagination -->

        <b-col class="d-flex align-items-center justify-content-center justify-content-sm-start" cols="12" sm="6">
          <span v-if="pagination.totalRows !==0" class="text-muted">Showing {{ pagination.from }} to {{ pagination.to }} of {{ pagination.totalRows }} entries</span>
        </b-col>

        <!-- Pagination -->

        <b-col class="d-flex align-items-center justify-content-center justify-content-sm-end" cols="12" sm="6">

          <b-pagination
              v-model="currentPage"
              :per-page="pagination.perPage"
              :total-rows="pagination.totalRows"
              class="mb-0 mt-1 mt-sm-0 "
              first-number
              last-number
              next-class="next-item"
              prev-class="prev-item">

            <template #prev-text>
              <feather-icon icon="ChevronLeftIcon" size="18"/>
            </template>

            <template #next-text>
              <feather-icon icon="ChevronRightIcon" size="18"/>
            </template>

          </b-pagination>

        </b-col>
      </b-row>

<!--      <b-tabs>-->
<!--        <b-tab title="Outstanding" active>-->
<!--          <outstanding-invoice/>-->

<!--        </b-tab>-->
<!--        <b-tab-->

<!--            title="Paid"-->
<!--        >-->
<!--          <paid-invoice/>-->
<!--         </b-tab>-->
<!--      </b-tabs>-->


    </b-card>


    <b-modal
        id="modal-center"
        v-model="showCreateInvoice"
        no-close-on-backdrop

        centered
        hide-footer
        size="lg"
        title="Create Invoice"
    >
      <b-overlay
          :show="invoiceModalLoading"
          rounded="sm"
      >
        <validation-observer ref="CreateInvoiceForm">
        <b-form @submit.prevent>
       <b-card>

         <b-row>

           <b-col cols="12">
             <h2 class="txt-black font-weight-bolder mb-2">Invoice #2343567</h2>
           </b-col>
           <b-col cols="5" >
             <div class="card-text mb-25">
               <p class="float-left">Issue Date: </p>
               <validation-provider
                   #default="{ errors }"
                   name="Issue Date"
                   rules="required"
               >
               <b-form-group>
                 <flat-pickr
                     v-model="form.issue_date"
                     :config="{ enableTime: false, noCalendar: false, dateFormat: 'Y-m-d',}"
                     class="form-control ml-1 mr-1 "
                     placeholder="Select Date"
                 />
               </b-form-group>
                 <small class="text-danger">{{ errors[0] }}</small>
               </validation-provider>
             </div>
             <div class="card-text mb-0">
               <p class="float-left"> Due Date :</p>
               <validation-provider
                   #default="{ errors }"
                   name="Due Date"
                   rules="required"
               >
               <b-form-group><flat-pickr
                   v-model="form.due_date"
                   :config="{ enableTime: false, noCalendar: false, dateFormat: 'Y-m-d',}"
                   class="form-control ml-1"
                   placeholder="Select Date"
               />
               </b-form-group>
                 <small class="text-danger">{{ errors[0] }}</small>
               </validation-provider>
             </div>
           </b-col>
           <b-col cols="1"></b-col>
           <b-col cols="6"  style="background-color: #EBF5FB; border-radius: 5px">
             <h4 class="mb-2 mt-2 txt-black font-weight-bolder">Invoice To:</h4>
             <b-form-group>
               <v-select
                   v-model="selected_practice"
                   :options="practiceList"
                   class="mb-2"
                   label="name"
                   placeholder="Please select medical practice"
               />
             </b-form-group>



           </b-col>
         </b-row>

         <hr class="invoice-spacing"/>
         <div class="table-responsive tbl-nl">
           <table
               aria-busy="false"
               aria-colcount="4"
               class="table b-table tbl-invoice"
               role="table"

           >
             <thead class="" role="rowgroup">

             <tr class="" role="row">
               <th aria-colindex="1" class="" role="columnheader" scope="col">
                 <div>Shift Date</div>
               </th>
               <th aria-colindex="2" class="" role="columnheader" scope="col">
                 <div>Hourly Rate</div>
               </th>
               <th aria-colindex="3" class="" role="columnheader" scope="col">
                 <div>HOURS</div>
               </th>
               <th aria-colindex="4" class="" role="columnheader" scope="col">
                 <div>PRICE</div>
               </th>
               <th aria-colindex="4" class="" role="columnheader" scope="col">
                 <div>ACTION</div>
               </th>
             </tr>
             </thead>
             <tbody role="rowgroup">

             <tr role="row" v-for="shift in ShiftList ">
               <td aria-colindex="1" class="" role="cell">
                 <p class="card-text font-weight-bold mb-25">
                   {{shift.date}}
                 </p>
               </td>
               <td aria-colindex="2" class="" role="cell">£{{shift.hourly_rate}}</td>
               <td aria-colindex="2" class="" role="cell">{{shift.hours}}</td>
               <td aria-colindex="4" class="" role="cell">£{{shift.price}}</td>
               <td aria-colindex="4" class="" role="cell">
                 <b-button
                     size=""
                     variant="outline-white"
                     class="btn-icon bg-white text-danger  "
                     v-b-tooltip.hover.top="'Remove'"
                     @click="removeShift(shift.id)"
                 >
                   <feather-icon icon="TrashIcon" size="18" />
                 </b-button>
               </td>
             </tr>


             <tr role="row">

               <td aria-colindex="4" colspan="2" class="" role="cell"></td>
               <td aria-colindex="2" class="" role="cell"></td>

               <td aria-colindex="2" class="py-2" role="cell">Total:</td>
               <td aria-colindex="4" class="font-weight-bolder" role="cell">£2,240.00</td>
             </tr>
             </tbody>
           </table>
         </div>




       </b-card>
        <b-card class="mt-1">

          <h4 class="mb-2 mt-2 txt-black font-weight-bolder">Invoice To:</h4>
          <b-row>
            <b-col cols="5">
              <label>Bank Name</label>
              <validation-provider
                  #default="{ errors }"
                  name="Bank Name"
                  rules="required"
              >
              <b-form-input
                  id="login-email"
                  v-model="form.bank_name"
                  name="login-email"
                  placeholder="e.g. Bank of England"
              />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>
            <b-col cols="4">
              <label>Account Number</label>
              <validation-provider
                  #default="{ errors }"
                  name="Account Number"
                  rules="required"
              >
              <b-form-input

                  v-model="form.bank_account_number"
                  name="login-email"
                  placeholder="xxxxxxxx"
              />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>
            <b-col cols="3">
              <label>Sort Code</label>
              <validation-provider
                  #default="{ errors }"
                  name="Sort Code"
                  rules="required"
              >
              <b-form-input

                  v-model="form.bank_sort_code"
                  name="login-email"
                  placeholder="xx-xx-xx"
              />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>
          </b-row>
          <b-form-checkbox
              v-model="save_my_account_bank_details"
              value="A"
              class="mt-1"
          >Save to my account</b-form-checkbox>



        </b-card>
        <div class="float-right">
          <b-button variant="primary" @click="createInvoice()">Submit</b-button>
          <b-button variant="danger" class="ml-1" @click="showCreateInvoice = false">Close</b-button>
        </div>
        </b-form>
        </validation-observer>
      </b-overlay></b-modal>
    <analytics ref="anlaysisModel" />

  </div>
</template>

<script>
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import {

  BAvatar,
  BBadge,
  BButton,
  BCard,
  BCardBody,
  BCardHeader,
  BCol,
  BImg,
  BLink,
  BMedia, BOverlay,
  BRow,
  VBTooltip,
  BListGroupItem,
  BCardTitle,
  BFormGroup,
  BTabs,
  BCardText,
  BTab,

  VBToggle,
  BModal, VBModal, BAlert,

  BButtonGroup,
  BFormCheckbox,
  BInputGroup,
  BInputGroupPrepend,
  BAvatarGroup,
  BFormInput,
  BTable,

  BDropdown,
  BDropdownItem,
  BPagination,
} from 'bootstrap-vue'
import MomentMixin from '@/mixins/MomentMixin'
import Ripple from 'vue-ripple-directive'
import flatPickr from 'vue-flatpickr-component'
import OutstandingInvoice from "@/views/invoice/outstanding-invoice";
import PaidInvoice from "@/views/invoice/paid-invoice";
import Analytics from "@/views/invoice/analytics";
import invoice from "@/apis/modules/invoice";
import {required} from '@validations'



export default {
  name: "index",
  components: {
    Analytics,
    PaidInvoice,
    OutstandingInvoice,
    BTabs,
    BCardText,
    BTab,
    flatPickr,
    VBTooltip,
    BMedia,
    BImg,
    BCard,
    BCardHeader,
    BRow,
    BCol,
    BButton,
    BAvatar,
    BLink,
    BCardBody,
    BBadge,
    VuePerfectScrollbar,
    BListGroupItem,
    BCardTitle,
    BFormGroup,
    VBToggle,
    BModal, VBModal, BAlert,
    BButtonGroup,
    BOverlay, BFormCheckbox,
    BInputGroup,
    BInputGroupPrepend,
    BAvatarGroup,
    BFormInput,
    BTable,
    BDropdown,
    BDropdownItem,
    BPagination,

  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  mixins: [MomentMixin],

  data() {
    return {
      required,
      filter:'',
      invoiceSummary:'',
      form:{
        issue_date:'',
        due_date:'',
        bank_name:'',
        bank_account_number:'',
        bank_sort_code:''
      },
      selected_practice:{},
      practiceList:[],
      ShiftList:[],
      save_my_account_bank_details:false,


      showCreateInvoice:false,
      invoiceModalLoading:false,
      tableLoading:false,
      noDataTable: '',
      currentPage: 1,
      pagination: {
        perPage: '',
        pageOptions: '',
        totalRows: '',
        from: '',
        to: ''
      },
      sortBy: '',
      sortDesc: true,
      sortDirection: 'asc',
      // filter: null,
      // filterOn: [],
      // infoModal: {
      //   id: 'info-modal',
      //   title: '',
      //   content: ''
      // },
      fields: [
        {
          key: 'invoice_id',
          label:'INVOICE ID',
        },
        {
          key: 'amount',
          label: 'Amount',
        },
        {
          key: 'bill_to',
          label: 'BILL TO',
        },

        {
          key: 'date',
          label: 'ISSUE DATE',
        },


        {
          key: 'status',
          label:'status',
        },
        {
          key: 'Action',
          label:' action',
        },
      ],
      items: [],
    }
  },
  watch:{

  async selected_practice(){
    this.ShiftList =[]
     await this.getShifts()
    }
  },

  methods:{
    async removeShift(id){
      if (this.ShiftList.length <= 1){
        this.showErrorMessage('at lease one shift want to create Invoice')
      }else {
        this.ShiftList = this.ShiftList.filter(x =>{
          return x.id !== id
        })
      }
    },
    async getInvoiceList(){
      try {
        this.tableLoading = true
        const Response = await invoice.getInvoicesList(this.currentPage, 10, this.sortBy,this.sortDesc)
        const dataArray = Response.data.data.map((x) => ({
          id:x.id,
          bill_to:x.practice.name,
          invoice_id:x.id,
          date:x.issue_date,
          amount:x.total_invoice_cost,
          status:x.state

        }))
        const paginationResponse = Response.data.meta
        this.currentPage = paginationResponse.current_page
        this.pagination.totalRows = paginationResponse.total
        this.pagination.perPage = paginationResponse.per_page
        this.pagination.from = paginationResponse.from
        this.pagination.to = paginationResponse.to
        this.tableLoading = false
        return dataArray


      }catch (error){
        this.convertAndNotifyError(error)
        this.tableLoading = false

      }
    },
    async createInvoice(){
      if (await this.$refs.CreateInvoiceForm.validate()) {
        try {
          this.tableLoading = true
          this.form.practice_id = this.selected_practice.value
          this.form.shift_ids = this.ShiftList.map((x) => (
              x.id
          ))
           const response = await invoice.submitInvoice(this.form)
          this.showSuccessMessage('Invoice create successful')
          this.showCreateInvoice = false
          this.tableLoading = false

        } catch (error) {
          this.convertAndNotifyError(error)
          this.tableLoading = false

        }
      }
    },
    async getPractice(){
      try {
        const response = await invoice.getPracticesForInvoice()
        this.practiceList = response.data.data.map((x) => ({
          name:x.name,
          value:x.id
        }))
      }catch (error){
        this.convertAndNotifyError(error)
      }
    },
    async getShifts(){
      try {
        const response = await invoice.getPNonInvoiceShifts(this.selected_practice.value)
        this.ShiftList = response.data.data.map((x) => ({
          id:x.id,
          date:this.momentFormat(x.start, 'dddd DD-MM-YYYY'),
          hourly_rate:x.locum_rate,
          hours:this.shiftTimeDeferent(x.start, x.end),
          price:this.totalShiftCost(x.start, x.end ,x.locum_rate )
        }))
      }catch (error){
        this.convertAndNotifyError(error)
      }
    },
    async getSummary(){
      try {
         this.invoiceSummary = (await invoice.getPInvoiceSummary()).data.data
      }catch (error){
        this.convertAndNotifyError(error)
      }
    },
    async openInvitationCrateModel(){
      this.showCreateInvoice = true
      await this.getPractice()
    },
    openAnaltics(){
      this.$refs.anlaysisModel.openModal()
    }
  },
  async mounted() {
   await this.getInvoiceList()
    await this.getSummary()
  }
}
</script>

<style  lang="scss">
@import '@core/scss/vue/libs/vue-select.scss'; /*print style*/
@import '@core/scss/vue/libs/vue-flatpicker.scss';

.shift-card{
  //margin-bottom: 0px;
  //padding-bottom: 10px;
}



</style>
