import api from '../axios';
import config from '@/constants/config';
const resource = '/api/mp/settings/';

export default {

  getInvoicesList :(page, paginate, filterQuery) => api.get(`api/locum/invoices?page=${page}&paginate=${paginate}&${filterQuery}`),
  getInvoiceDetails :(id) => api.get(`api/locum/invoices/${id}`),
  getPracticesForInvoice :() => api.get(`api/locum/invoices/non-invoiced-practices`),
  getPNonInvoiceShifts :(id) => api.get(`api/locum/invoices/non-invoiced-shifts/${id}`),
  getPInvoiceSummary:() => api.get(`api/locum/invoices/summary`),
  submitInvoice:(payload) => api.post('api/locum/invoices',payload)
};
